import React from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import OwlCarousel from 'react-owl-carousel3';
import PlatinumSponsors from '../LaxButton/PlatinumSponsors';
import GoldSponsors from '../LaxButton/GoldSponsors';
import Footer from '../Common/Footer';

const options = {
    loop: false,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    navText: [
        "<i class='icofont-rounded-left'></i>",
        "<i class='icofont-rounded-right'></i>"
    ],
    responsive: {
        0: {
            items:2,
        },
        768: {
            items:3,
        },
        1200: {
            items:5,
        }
    }
}
 
class Sponsor extends React.Component {
    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }
    render(){
        return (
            <React.Fragment>
                {/* <div className="page-title-area item-bg2">
                    <div className="container">
                        <h1>Sponsors</h1>
                        <span>Check Who Makes This Event Possible!</span>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>Sponsors</li>
                        </ul>
                    </div>
                </div> */}

                <section style={{display:"none"}} className="partner-area ptb-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="partner-title platinum-sponsor">
                                    <PlatinumSponsors />
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                    <div className="partner-item">
                                        <Link to="#">
                                            <img width={200} src={("./sponsor/Veritas.svg")} alt="sponsor" />
                                            <img width={200}  src={("./sponsor/Veritas.svg")} alt="sponsor" />
                                        </Link>
                                    </div>
                                </div>
                           

                            <div className="col-lg-12">
                                <div className="border"></div>
                            </div>

                            <div className="col-lg-12">
                                <div className="partner-title gold-sponsor">
                                    <GoldSponsors />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                    <div  style={{display:"flex",justifyContent:"center",alignItems:"center"}}  className="partner-item">
                                        <Link style={{width:200}}  to="#">
                                            <img  src={("./sponsor/vmware.png")} alt="sponsor" />
                                            <img  src={("./sponsor/vmware.png")} alt="sponsor" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div  style={{display:"flex",justifyContent:"center",alignItems:"center"}}  className="partner-item">
                                        <Link style={{width:200}} to="#">
                                            <img  src={("./sponsor/Broadcom_Ltd_Logo.svg")} alt="sponsor" />
                                            <img  src={("./sponsor/Broadcom_Ltd_Logo.svg")} alt="sponsor" />
                                        </Link>
                                    </div>
                                </div>
                                <div  className="col-lg-4 col-md-4">
                                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}  className="partner-item">
                                        <Link style={{width:200}}  to="#">
                                            <img  src={("./sponsor/Commvault_logo_2019.svg")} alt="sponsor" />
                                            <img  src={("./sponsor/Commvault_logo_2019.svg")} alt="sponsor" />
                                        </Link>
                                    </div>
                                </div>
                            {/* <OwlCarousel 
                                className="gold-partner-slides owl-carousel owl-theme"
                                {...options}
                            >
                                <div className="col-lg-12 col-md-12">
                                    <div className="partner-item">
                                        <Link to="#">
                                            <img src={require("../../assets/images/gold-partner1.png")} alt="sponsor" />
                                            <img src={require("../../assets/images/gold-partner1.png")} alt="sponsor" />
                                        </Link>
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="partner-item">
                                        <Link to="#">
                                            <img src={require("../../assets/images/gold-partner2.png")} alt="sponsor" />
                                            <img src={require("../../assets/images/gold-partner2.png")} alt="sponsor" />
                                        </Link>
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="partner-item">
                                        <Link to="#">
                                            <img src={require("../../assets/images/gold-partner3.png")} alt="sponsor" />
                                            <img src={require("../../assets/images/gold-partner3.png")} alt="sponsor" />
                                        </Link>
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="partner-item">
                                        <Link to="#">
                                            <img src={require("../../assets/images/gold-partner4.png")} alt="sponsor" />
                                            <img src={require("../../assets/images/gold-partner4.png")} alt="sponsor" />
                                        </Link>
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="partner-item">
                                        <Link to="#">
                                            <img src={require("../../assets/images/gold-partner5.png")} alt="sponsor" />
                                            <img src={require("../../assets/images/gold-partner5.png")} alt="sponsor" />
                                        </Link>
                                    </div>
                                </div>
                            </OwlCarousel> */}
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}
 
export default Sponsor;