import React from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import LaxDiv from '../Shared/LaxDiv';
 
class Speakers extends React.Component {

    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }

    render(){
        return (
            <section className="speakers-area-two" style={{paddingTop:30}}  id="speaker">
                <div className="container">
                    <div className="section-title">
                        {/* <span>Listen to the Event Speakers</span> */}
                        <h2>From the Desk of Hemant Tiwari</h2>
                        <div className="bar"></div>
                        {/* <LaxDiv text="Speakers" dataPreset="driftRight" /> */}
                        {/* <Link to="#" className="btn btn-primary">View More Speakers</Link> */}
                    </div>

                    <div className="row">
                    <div className="col-lg-8 col-sm-6">
                            {/* <div className="single-speakers-box"> */}
                            <p>Dear Esteemed Partners,</p>
                                <p>I am delighted to host you at our upcoming Partner Summit between July 10th – 12th at the opulent ITC Rajputana, Jaipur a momentous occasion where we will come together to shape the future of our partnership and drive collective success.</p>
                               <p>We recognize the invaluable role that our partners play in our journey towards excellence. Your steadfast commitment, innovative spirit, and unwavering support have been instrumental in our shared achievements, and for that, we extend our deepest appreciation.</p>
                           <p>Together, we will explore emerging industry trends, identify new avenues for collaboration, and leverage our collective strengths to deliver unparalleled value to our joint customers.</p>
                             <p>I am thrilled to announce the establishment of our Partner Advisory Board, a select group of esteemed partners who will serve as trusted advisors, providing invaluable insights and guidance to help steer our partnership towards greater heights. This esteemed board will play a pivotal role in shaping our strategic direction, fostering innovation, and driving mutual success.</p>
                              <p>At the core of our discussions will be a commitment to excellence and customer-centricity. By prioritising the needs of our customers and delivering best-in-class solutions and services, we can position ourselves as true leaders in the industry and drive sustainable growth for years to come.</p>
                           <p>I am confident that our collective expertise, combined with the invaluable insights of our Partner Advisory Board, will propel us towards even greater success in the years ahead. Together, let us seize this opportunity to strengthen our partnership, drive innovation, and deliver exceptional value to our customers.</p>
                            <p>I eagerly anticipate our time together at the Partner Summit, where we will forge deeper connections, foster collaboration, and embark on a journey of mutual growth and prosperity.</p>
                   <p>Warm regards,<br/>

Hemant Tiwari<br/>
MD and GM Hitachi Vantara India & SAARC
</p>
                               {/* <p>Despite the ongoing challenges in the marketplace, I am thrilled to see the positive momentum in our Hitachi Vantara partner business as we head into 2023. Together, we have the power to address the most critical business challenges surrounding agility, data access, insights, and hybrid cloud strategy. Our goal is to provide predictable, stable, and efficient solutions that empower businesses to thrive.</p> */}
                               {/* <p>The Hitachi Vantara India Partner Summit has firmly established itself as a must-attend conference for our partners. This year, the event will be held from July 12th to 14th in the enchanting city of Siliguri, West Bengal. It presents a unique opportunity for you to gain front-row access to emerging technologies such as cloud, object storage, Internet of Things (IoT), and much more.</p> */}
                              {/* <p>As a valued partner, your participation in this summit is an opportunity to expand your value by leveraging the diverse range of Hitachi Vantara offerings. You will have the chance to meet and interact with our esteemed leadership team and forge collaborative alliances with fellow partners, enabling us to jointly sell our innovative solutions.</p> */}
                           {/* <p>We firmly believe that partnering with Hitachi Vantara brings together the best of both worlds – leading technology, rich incentives for greater profitability, and a spirit of collaboration and teamwork that will undoubtedly propel you to success.</p> */}
                             {/* <p>We are excited to have you join us at the Hitachi Vantara India Partner Summit 2023. Let us seize this opportunity to shape the future of business together. Together, we can achieve remarkable heights.</p> */}
                                {/* <div className="speakers-image">
                                    <img 
                                        src={require("../../assets/images/speakers1.jpg")}
                                        alt="speaker" 
                                    />
                                </div>

                                <div className="speakers-content">
                                    <h3><Link to="#">James Anderson</Link></h3>
                                    <span>Founder & CEO</span>

                                    
                                </div> */}
                            {/* </div> */}
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="single-speakers-box">
                                <div className="speakers-image">
                                    <img 
                                        src={"/hemant-min.jpg"}
                                        alt="speaker" 
                                    />
                                </div>

                                {/* <div className="speakers-content">
                                    <h3><Link to="#">Hemant Tewari</Link></h3>
                                    <span>Head of Commercial Sales, APAC</span>

                                    
                                </div> */}
                            </div>
                        </div>

                        
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Speakers;