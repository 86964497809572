import React from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import { isMobile } from "react-device-detect";

class MainBanner extends React.Component {
  state = {
    days: "",
    hours: "",
    minutes: "",
    seconds: "",
    isOpen: false,
  };

  openModal = () => {
    this.setState({ isOpen: true });
  };

  makeTimer = () => {
    let endTime = new Date("July 10, 2024 10:00:00");

    let endTimeParse = Date.parse(endTime) / 1000;
    let now = new Date();
    let nowParse = Date.parse(now) / 1000;
    let timeLeft = endTimeParse - nowParse;
    let days = Math.floor(timeLeft / 86400);
    let hours = Math.floor((timeLeft - days * 86400) / 3600);
    let minutes = Math.floor((timeLeft - days * 86400 - hours * 3600) / 60);
    let seconds = Math.floor(
      timeLeft - days * 86400 - hours * 3600 - minutes * 60
    );
    if (hours < "10") {
      hours = "0" + hours;
    }
    if (minutes < "10") {
      minutes = "0" + minutes;
    }
    if (seconds < "10") {
      seconds = "0" + seconds;
    }
    this.setState({
      days,
      hours,
      minutes,
      seconds,
    });
  };

  componentDidMount() {
    this.myInterval = setInterval(() => {
      this.makeTimer();
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  render() {
    return (
      <React.Fragment>
        {/* <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId="cRXm1p-CNyk"
          onClose={() => this.setState({ isOpen: false })}
        /> */}

        <div className="main-banner item-bg1">
          <img src="/banner6.jpg" style={{marginTop:60}} width={"100%"}/>
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="main-banner-content">
                  {/* <img
                  onClick={(e)=>{
                    let hero = document.getElementById("about");
                    console.log(hero);
                    e.preventDefault(); // Stop Page Reloading
                    hero &&
                      hero.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });

                        // firebase.auth().signOut();
                      
                  }}
                    src={require("../../assets/images/mainlogo2.png")}
                    width={isMobile?"100%":"70%"}
                    style={{
                    marginTop:-150
                    }}
                  /> */}
                  {/* <p>Are you <span>ready</span> to attend?</p> */}
                  {/* <h1>Hitachi <span>Vantara Partner Summit</span> <b>2</b><b>0</b><b>2</b><b>2</b></h1> */}
                  {/* <br/><h1>Together We Go Further</h1> */}
                  {/* <ul>
                    <li>
                      <i className="icofont-compass"></i> JW Marriott Bengaluru
                      Prestige Golfshire{" "}
                    </li>
                    <li>
                      <i className="icofont-calendar"></i> 16-18 June, 2022
                    </li>
                  </ul> */}

                  {/* <div className="button-box"> */}
                    {/* <Link to="#" className="btn btn-primary">
                      Register Now!
                    </Link> */}
                    {/* <Link
                      onClick={(e) => {
                        e.preventDefault();
                        this.openModal();
                      }}
                      to="#"
                      className="video-btn popup-youtube"
                    >
                      <i className="icofont-ui-play"></i> Watch Promo Video
                    </Link> */}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="event-countdown countdown1">
            <div id="timer">
              <div style={{color:"#000"}} id="days">
                {this.state.days} <span style={{color:"#000"}}>Days</span>
              </div>
              <div style={{color:"#000"}} id="hours">
                {this.state.hours} <span style={{color:"#000"}}>Hours</span>
              </div>
              <div style={{color:"#000"}} id="minutes">
                {this.state.minutes} <span style={{color:"#000"}}>Minutes</span>
              </div>
              <div style={{color:"#000"}} id="seconds">
                {this.state.seconds} <span style={{color:"#000"}}>Seconds</span>
              </div>
            </div>
          </div>

          {/* <div className="shape1">
            <img
              src={require("../../assets/images/shapes/1.png")}
              alt="shape1"
            />
          </div>

          <div className="shape2 rotateme">
            <img
              src={require("../../assets/images/shapes/2.png")}
              alt="shape2"
            />
          </div> */}

          {/* <div className="shape3 rotateme">
            <img
              src={require("../../assets/images/shapes/3.png")}
              alt="shape3"
            />
          </div> */}

          {/* <div className="shape4">
            <img
              src={require("../../assets/images/shapes/4.png")}
              alt="shape4"
            />
          </div> */}
        </div>
      </React.Fragment>
    );
  }
}

export default MainBanner;
