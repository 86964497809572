import React from "react";
import { Link } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <>
       <section className="about-area ptb-120 bg-image" id="about1">
                <div className="container">
                    <div className="row h-100 align-items-center">
                        <div className="col-lg-6">
                        <div
                className="about-content"
                style={{ fontSize: 18, textAlign: "center" }}
              >
                <h3>Join us at ITC Rajputana , Jaipur</h3>
                <br />
                <p style={{ fontSize: 18 }}>
                ITC Rajputana Jaipur ITC Rajputana Jaipur, offers an unparalleled business experience in the heart of the Pink City. This luxurious hotel seamlessly blends Rajasthan's regal heritage with modern amenities, providing sophisticated accommodations and state-of-the-art meeting and conference facilities. With diverse gourmet dining options, rejuvenating wellness amenities, and impeccable service, ITC Rajputana creates an environment that is both refined and inspiring, ensuring an unforgettable experience.
                </p>
                <button
                  to="/pricing-1"
                  className="btn btn-primary"
                  onClick={() => {
                    window.open(
                      "https://www.itchotels.com/in/en/itcrajputana-jaipur?utm_source=google&utm_medium=cpc&utm_content=search&utm_campaign=HQ-DTL|HTL|Jaipur|ITCRajputana|Search|Conversions|PANIndia|always-on|Owned|LC|GGL|na|na|Leisure|JAILC|Brand&gad_source=1&gclid=Cj0KCQjwpNuyBhCuARIsANJqL9McG0TqBP4rRhevjg2tCSvwDvlU03iUsTP2POh6G6U4y30oNDN7zEQaAhGjEALw_wcB"
                    );
                  }}
                >
                  MORE ABOUT THE RESORT  
                </button>
              </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="about-image">
                                {/* <img src="./hotel1.jpg" className="about-img1" alt="about" /> */}

                                <img src="./hotel3.jpg" className="demo" alt="about" />

                                {/* <img src={require("../../assets/images/shapes/5.png")} className="shape-img" alt="about" /> */}

                                {/* <LaxButton buttonText="Explore More About" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
       
        <footer className="footer-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="single-footer-widget" style={{color:"#fff"}}>
                  <h3>Venue</h3>
                  <span style={{color:"#fff"}}>
                    <i style={{color:"#fff"}} className="icofont-calendar"></i> 10th July to 12th July,
                    2024
                  </span>

                  <p className="location" style={{color:"#fff"}}>
                    <i className="icofont-google-map"></i> ITC RAJPUTANA JAIPUR
                  </p>

                  <a  style={{color:"#fff"}} className="contact-authority">
                    <i className="icofont-phone"></i> Contact Us
                    <br />
                    <a style={{color:"#fff"}} href="mailto:neha.naik@hitachivantara.com">
                      neha.naik@hitachivantara.com
                    </a>
                    <br />
                    <a style={{color:"#fff"}} href="mailto:Sneha.nair@framez.sg">
                      Sneha.nair@framez.sg
                    </a>
                  </a>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="single-footer-widget">
                  <h3>Social Connection</h3>
                  {/* <p>Don't miss Link thing! Receive daily news You should connect social area for Any Proper Updates Anytime.</p> */}

                  <ul className="social-links">
                    <li>
                      <a
                        href="https://www.facebook.com/HitachiVantara"
                        className="facebook"
                        target="_blank"
                      >
                        <i className="icofont-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/HitachiVantara"
                        className="twitter"
                        target="_blank"
                      >
                        <i className="icofont-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/hitachi-vantara/"
                        className="linkedin"
                        target="_blank"
                      >
                        <i className="icofont-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/c/HitachiVantara"
                        className="youtube"
                        target="_blank"
                      >
                        <i className="icofont-youtube-play"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="copyright-area">
                  {/* <div className="logo">
                    <a
                      href="https://www.hitachivantara.com/"
                      target="_blank"
                      to="/"
                    >
                      <img
                        src={require("../../assets/images/hitachi4.png")}
                        width={200}
                        alt="logo"
                      />
                    </a>
                  </div> */}
                  {/* <ul>
                                    <li><Link to="/blog-1">Blog</Link></li>
                                    <li><Link to="/contact">Contact</Link></li>
                                    <li><Link to="#">Ticket</Link></li>
                                    <li><Link to="#">Venue</Link></li>
                                    <li><Link to="#">Terms & Conditions</Link></li>
                                </ul> */}
                  <p style={{color:"#fff"}}>
                    Copyright <i className="icofont-copyright"></i> 2024 Framez.
                    All rights reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
