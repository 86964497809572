import React from "react";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { history } from "../../history";
import firebase from "firebase";
import { Spinner } from "reactstrap";
import { isMobile, withOrientationChange } from "react-device-detect";
import Select from "react-select";
import { Form, FormGroup } from "reactstrap";
import DatePicker from "react-datepicker";
import Moment from "moment";

// import 'react-datetime-picker/dist/DateTimePicker.css';
import "react-datepicker/dist/react-datepicker.css";

// import 'react-calendar/dist/Calendar.css';
// import 'react-clock/dist/Clock.css';
class Registerform extends React.Component {
  state = {
    name: "",
    email: "",
    password: "",
    city: "",
    position: "",
    company: "",
    phone: "",
    isloading: false,
    successAlert: false,
    errorAlert: false,
    errormsg: "",
    photo:null,
    category: null,
    dieteryrequirements:"",
    othercomments:"",
    accessibilityrequirements:"",
    mealpref:null,
    lastname:"",
    startDate: new Date("2024/07/10"),
    checkoutDate: new Date("2024/07/12"),
    tshirt: null,
  };
  // const [value, onChange] = useState(new Date());
  onChange = (data) => {
    this.setState({ startDate: data });
  };
  handleAlert = (state, value) => {
    this.setState({ [state]: value });
    // history.push("/login");
  };
  handleAlert2 = (state, value) => {
    this.setState({ [state]: value });
  };
  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.category === null) {
      this.setState({
        errorAlert: true,
        errormsg: "Please select category.",
      });
    }else if (this.state.photo === null) {
      this.setState({
        errorAlert: true,
        errormsg: "Please add Aadhaar card",
      });
    } else if (this.state.tshirt === null) {
      this.setState({
        errorAlert: true,
        errormsg: "Please select Shirt Size.",
      });
    }  else if (this.state.mealpref === null) {
      this.setState({
        errorAlert: true,
        errormsg: "Please select Meal preference.",
      });
    } else {
      this.setState({
        isloading: true,
      });
      var fileext = this.state.filetype.includes("png") ? ".png" : ".jpg";
        firebase
          .storage()
          .ref("files/" + new Date().getTime() + fileext)
          .putString(
            this.state.photo.replace(/^data:image\/[a-z]+;base64,/, ""),
            "base64",
            { contentType: this.state.filetype }
          )
          // .put(this.state.photo)
          .then((snapshot) => {
            snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log("File available at1", downloadURL);
  
              var registeruser = firebase
        .app()
        .functions("asia-east2")
        .httpsCallable("createuser");
      registeruser({
        name: this.state.name,
        lastname: this.state.lastname,
        company: this.state.company,
        aadhar: downloadURL,
        position: this.state.position,
        phone: this.state.phone,
        email: this.state.email,
        city: this.state.city,
        time: new Date().toString(),
        category: this.state.category.value,
        mealpref:this.state.mealpref.value,
        accessibilityrequirements:this.state.accessibilityrequirements,
        dieteryrequirements:this.state.dieteryrequirements,
        othercomments:this.state.othercomments,
        tshirt: this.state.tshirt.value,
        checkindate: this.state.startDate.toString(),
        checkoutdate: this.state.checkoutDate.toString(),
        // password: this.state.password
      }).then((data) => {
        console.log(data);
        console.log(data.data.err);
        console.log(JSON.stringify(data));
        if (data.data.err == 0) {
          console.log("REgister done");
          this.setState({
            successAlert: true,
            name: "",
            email: "",
            password: "",
            city: "",
            position: "",
            company: "",
            phone: "",
            photo:null,
            isloading: false,
            errorAlert: false,
            errormsg: "",
            category: null,
            dieteryrequirements:"",
            othercomments:"",
            accessibilityrequirements:"",
            mealpref:null,
            lastname:"",
            startDate: new Date("2024/07/10"),
            checkoutDate: new Date("2024/07/12"),
            tshirt: null,
          });
          // history.push("/login");
        } else if (data.data.err === 1) {
          console.log("Already registered");
          this.setState({
            errorAlert: true,
            errormsg: "Already Registered",
            isloading: false,
          });
        } else {
          console.log("There was an error");
          this.setState({
            errorAlert: true,
            errormsg: "There was an error",
            isloading: false,
          });
        }
      });
            });
          });
      
    }
  };
  render() {
    return (
      <section className="signup-area">
        <SweetAlert
          success
          title="Success"
          show={this.state.successAlert}
          onConfirm={() => this.handleAlert("successAlert", false)}
        >
          <p style={{ color: "#000" }} className="sweet-alert-text">
            Thank you for registering. Please check your mail.
          </p>
        </SweetAlert>

        <SweetAlert
          title="Error"
          show={this.state.errorAlert}
          onConfirm={() => this.handleAlert2("errorAlert", false)}
        >
          <p style={{ color: "#000" }} className="sweet-alert-text">
            {this.state.errormsg}
          </p>
        </SweetAlert>
        <div>
          <div className="signup-form">
            {/* <h3>Registration</h3> */}
           <h3>Registrations are now closed for the summit</h3>
            <form
              style={{display:"none"}}
              onSubmit={this.onSubmit}
            >
              <div className="form-group">
                {/* <label>Name</label> */}
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>

              <div className="form-group">
                {/* <label>Name</label> */}
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  value={this.state.lastname}
                  onChange={(e) => this.setState({ lastname: e.target.value })}
                />
              </div>

              <div className="form-group">
                {/* <label>Email</label> */}
                <input
                  required
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>

              <div className="form-group">
                {/* <label>Organization</label> */}
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Organization"
                  value={this.state.company}
                  onChange={(e) => this.setState({ company: e.target.value })}
                />
              </div>
              <div className="form-group">
                {/* <label>Designation</label> */}
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Job Title"
                  value={this.state.position}
                  onChange={(e) => this.setState({ position: e.target.value })}
                />
              </div>
              <div className="form-group">
                {/* <label>Category</label> */}
                <Select
                  required
                  className="React"
                  classNamePrefix="select"
                  value={this.state.category}
                  onChange={(a) => {
                    this.setState({
                      category: a,
                    });
                  }}
                  placeholder="Delegate Type"
                  options={[
                    {
                      value: "Partner",
                      label: "Partner",
                    },
                    {
                      value: "Sponsor",
                      label: "Sponsor",
                    },
                    {
                      value: "Hitachi Vantara",
                      label: "Hitachi Vantara",
                    },
                  ]}
                />
                {/* <Label>Company size </Label> */}
              </div>
              <div className="form-group">
                {/* <label>Mobile Number</label> */}
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Mobile Number"
                  value={this.state.phone}
                  onChange={(e) => this.setState({ phone: e.target.value })}
                />
              </div>
              <div className="form-group">
                {/* <label>City</label> */}
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="City"
                  value={this.state.city}
                  onChange={(e) => this.setState({ city: e.target.value })}
                />
              </div>
              <div className="form-group">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <label
                    style={{ marginTop: 5, marginRight: 20, width: "50%" }}
                  >
                    Check-in Date
                  </label>
                  <DatePicker
                    selectsStart
                    selected={this.state.startDate}
                    onChange={this.onChange}
                    name="startDate"
                    minDate={new Date("2024/07/10")}
                    maxDate={new Date("2024/07/11")}
                    dateFormat="dd MMM"
                  />
                </div>
              </div>

              <div className="form-group">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <label
                    style={{ marginTop: 5, marginRight: 20, width: "50%" }}
                  >
                    Check-out Date
                  </label>
                  <DatePicker
                    selectsStart
                    selected={this.state.checkoutDate}
                    onChange={(date) => {
                      this.setState({
                        checkoutDate: date,
                      });
                    }}
                    name="checkoutDate"
                    minDate={new Date("2024/07/12")}
                    maxDate={new Date("2024/07/12")}
                    dateFormat="dd MMM"
                  />
                </div>
              </div>
              <div className="form-group">
                <Select
                  required
                  className="React"
                  classNamePrefix="select"
                  value={this.state.mealpref}
                  onChange={(a) => {
                    this.setState({
                      mealpref: a,
                    });
                  }}
                  placeholder="Meal preference "
                  options={[
                    {
                      value: "Veg",
                      label: "Veg",
                    },
                    {
                      value: "Non Veg ",
                      label: "Non Veg ",
                    },
                  ]}
                />
                {/* <Label>Company size </Label> */}
              </div>
              <div className="form-group">
                {/* <label>City</label> */}
                <input
                  
                  type="text"
                  className="form-control"
                  placeholder="Dietary requirements"
                  value={this.state.dieteryrequirements}
                  onChange={(e) => this.setState({ dieteryrequirements: e.target.value })}
                />
              </div>
              <div className="form-group">
                {/* <label>City</label> */}
                <input
                  
                  type="text"
                  className="form-control"
                  placeholder="Other Comments"
                  value={this.state.othercomments}
                  onChange={(e) => this.setState({ othercomments: e.target.value })}
                />
              </div>
              
              <div className="form-group">
                {/* <label>City</label> */}
                <input
                  
                  type="text"
                  className="form-control"
                  placeholder="Accessibility requirements"
                  value={this.state.accessibilityrequirements}
                  onChange={(e) => this.setState({ accessibilityrequirements: e.target.value })}
                />
              </div>
              <div className="form-group">
                <Select
                  required
                  className="React"
                  classNamePrefix="select"
                  value={this.state.tshirt}
                  onChange={(a) => {
                    this.setState({
                      tshirt: a,
                    });
                  }}
                  placeholder="Select your Shirt Size"
                  options={[
                    {
                      value: "S -36",
                      label: "S -36",
                    },
                    {
                      value: "M - 38",
                      label: "M - 38",
                    },
                    {
                      value: "L - 40",
                      label: "L - 40",
                    },
                    {
                      value: "XL - 42",
                      label: "XL - 42",
                    },
                    {
                      value: "XXL - 44",
                      label: "XXL - 44",
                    },
                    {
                      value: "3XL - 46",
                      label: "3XL - 46",
                    },
                  ]}
                />
                {/* <Label>Company size </Label> */}
              </div>
              {this.state.photo ? (
                <div style={{ padding: 10 }}>
                  <img width={"100%"} src={this.state.photo} />
                </div>
              ) : null}
              <div style={{ display: "flex", flex: 1,alignItems:"center" }}>
                <p style={{ textAlign: "justify", flex: 2 }}>
                  Please upload your Aadhaar card
                </p>
                <input
                  id="myInput"
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  ref={(ref) => (this.upload = ref)}
                  style={{ display: "none" }}
                  onChange={(event) => {
                    var file = event.target.files[0];
                    console.log(file);
                    if (file.size > 4097152) {
                      alert("Please select file size less than 4mb");
                    } else {
                      console.log(file.type);
                      if (
                        !(
                          file.type.includes("png") ||
                          // file.type.includes("pdf") ||
                          file.type.includes("jpg") ||
                          file.type.includes("jpeg")
                        )
                      ) {
                        alert("Please select only JPG or PNG file");
                      } else {
                        var imagebase64 = "";
                        var reader = new FileReader();
                        reader.onloadend = () => {
                          imagebase64 = reader.result;
                          this.setState({
                            photo: imagebase64,
                            filetype: file.type,
                          });
                        };
                        reader.readAsDataURL(file);
                      }
                    }
                    // this.setState({file});
                  }}
                />
                <div
                  style={{
                    flex: 1,
                    marginTop: 10,
                    marginLeft:10
                  }}
                >
                  <div
                    className="registerbutton2"
                    disabled={this.state.isloading}
                    color="warning"
                    style={{
                      margin: 10,
                      // flex: 1,
                      paddingTop: 30,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      height: 30,
                    }}
                    type="submit"
                    onClick={() => {
                      this.upload.click();
                    }}
                  >
                    Upload
                  </div>
                </div>
              </div>
              {this.state.category != null ? (
                <div>
                  {" "}
                  {this.state.category.value === "Partner" ? (
                    <p style={{ color: "rgb(204, 0, 0)", textAlign: "left" }}>
                      • Hotel accommodation for partner delegate is
                      complimentary for summit the days. (10th - 12th July)
                      <br />
                      • Any incidental in the rooms during the summit days to be
                      borne by the delegate directly. <br />• Room extension
                      charges outside the summit days to be borne by the
                      delegate.
                    </p>
                  ) : (
                    <p style={{ color: "rgb(204, 0, 0)", textAlign: "left" }}>
                      • Hotel Rooms will be reserved for the Summit days only,
                      room charges & any incidental charges to be borne by the
                      delegates directly.
                    </p>
                  )}
                </div>
              ) : null}

              {/* <p style={{color:"rgb(204, 0, 0)"}}>*Hitachi Vantara Sponsorship is Valid only for 12th - 14th June  */}
              {/* <br/>Outside the conference dates will be on direct</p> */}
              {/* <div className="form-group">
                                    <label>Password</label>
                                    <input 
                                        type="password" 
                                        className="form-control" 
                                        placeholder="Password" 
                                        value={this.state.password}
                                        onChange={e => this.setState({ password: e.target.value })}
                                    />
                                </div> */}
              <p>
                By clicking the 'Register button below, you hereby consent to
                receive commercial communications from Hitachi Vantara,
                including by phone and email in relation to Hitachi Vantara's
                products, servicesand events.
              </p>
              <button
                disabled={this.state.isloading}
                type="submit"
                style={{ zIndex: 0 }}
                className="btn btn-primary"
              >
                {this.state.isloading ? <Spinner color="red" /> : "Register"}
              </button>
              <p style={{ textAlign: "left" }}>
                Processing of your personal data is based on your consent,
                subject to our{" "}
                <a
                  style={{ color: "#cc0000" }}
                  href="https://www.hitachivantara.com/en-us/preference-center"
                  target="_blank"
                >
                  Privacy Policy
                </a>
                .
              </p>
              {/* <p>
                  Already a registered user? <Link to="/login">Login!</Link>
                </p> */}
            </form>
          </div>
          {/* </div> */}
        </div>
      </section>
    );
  }
}

export default Registerform;
