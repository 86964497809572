import React from "react";
import lax from "lax.js";
import { Link } from "react-router-dom";
import LaxDiv from "../Shared/LaxDiv";
import ReactPlayer from "react-player";

class Video extends React.Component {
  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }

  render() {
    return (
      <section id="speaker2" className="speakers-area ptb-120 pb-0">
        <div className="container">
          <div
            className="section-title"
            style={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <span>Listen to the Event Speakers</span> */}
            <h2>Past Year Highlights</h2>
            <div className="bar"></div>
            {/* <LaxDiv text="Speakers" dataPreset="driftRight" /> */}
            {/* <Link to="#" className="btn btn-primary">View More Speakers</Link> */}
          </div>
        </div>
        <div className="row m-0">
          <div
            className="col-lg-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {/* <h5 style={{textAlign:"center"}}>Will be announced soon</h5> */}
            <ReactPlayer
              onContextMenu={(e) => e.preventDefault()}
              config={{ file: { attributes: { controlsList: "nodownload" } } }}
              playing={true}
              controls={true}
              url="/hitachi.mp4"
              light={
                <img src="/Thumbnail-min.jpg" alt="Thumbnail" />
              }
            />

            {/* <ReactPlayer url="https://www.youtube.com/watch?v=LXb3EKWsInQ" /> */}
          </div>
        </div>
      </section>
    );
  }
}

export default Video;
