// Initialize Firebase

import * as firebase from "firebase/app"
import 'firebase/analytics'
import 'firebase/functions'
import 'firebase/database'

// production code for console.log
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  console.log("Development");
} else {
  console.log("Production");
  console.log = () => {};

}
const config = {
  apiKey: "AIzaSyDVGVIYVebLlR_1MHu2-6NSftu6kgsFipo",
  authDomain: "hvpartnersummit2024.firebaseapp.com",
  databaseURL: "https://hvpartnersummit2024-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "hvpartnersummit2024",
  storageBucket: "hvpartnersummit2024.appspot.com",
  messagingSenderId: "213602927590",
  appId: "1:213602927590:web:e479a181899323bbf4cc27",
  measurementId: "G-WMSH9QSBXM"
};

firebase.initializeApp(config)
firebase.analytics();