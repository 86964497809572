import React from 'react';
import lax from 'lax.js';
import { Link } from 'react-router-dom';
import LaxDiv from '../Shared/LaxDiv';
 
class Speakers extends React.Component {

    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }

    render(){
        return (
            <section id="speaker2" className="speakers-area ptb-120 pb-0">
                <div className="container">
                    <div className="section-title">
                        <span>Listen to the Event Speakers</span>
                        <h2>Who's Speaking</h2>
                        <div className="bar"></div>
                        {/* <LaxDiv text="Speakers" dataPreset="driftRight" /> */}
                        {/* <Link to="#" className="btn btn-primary">View More Speakers</Link> */}
                    </div>
                </div>
                {/* <h5 style={{textAlign:"center"}}>Will be announced soon</h5> */}
                <div className="row m-0" style={{display:"none"}}>
                   
                    <div className="col-lg-3 col-sm-6 p-3" style={{display:"flex",marginTop:10,justifyContent:"center"}}>
                    <img src={"./speakers/1.png"} alt="Speaker" />
                       
                    </div>
                    <div className="col-lg-3 col-sm-6 p-3" style={{display:"flex",marginTop:10,justifyContent:"center"}}>
                    <img src={"./speakers/2.png"} alt="Speaker" />
                       
                    </div>
                    <div className="col-lg-3 col-sm-6 p-3" style={{display:"flex",marginTop:10,justifyContent:"center"}}>
                    <img src={"./speakers/3.png"} alt="Speaker" />
                       
                    </div>
                    <div className="col-lg-3 col-sm-6 p-3" style={{display:"flex",marginTop:10,justifyContent:"center"}}>
                    <img src={"./speakers/4.png"} alt="Speaker" />
                       
                    </div>
                    <div className="col-lg-3 col-sm-6 p-3" style={{display:"flex",marginTop:10,justifyContent:"center"}}>
                    <img src={"./speakers/5.png"} alt="Speaker" />
                       
                    </div>
                    <div className="col-lg-3 col-sm-6 p-3" style={{display:"flex",marginTop:10,justifyContent:"center"}}>
                    <img src={"./speakers/6.png"} alt="Speaker" />
                       
                    </div>
                    <div className="col-lg-3 col-sm-6 p-3" style={{display:"flex",marginTop:10,justifyContent:"center"}}>
                    <img src={"./speakers/7.png"} alt="Speaker" />
                       
                    </div>
                    <div className="col-lg-3 col-sm-6 p-3" style={{display:"flex",marginTop:10,justifyContent:"center"}}>
                    <img src={"./speakers/8.png"} alt="Speaker" />
                       
                    </div>
                    <div className="col-lg-3 col-sm-6 p-3" style={{display:"flex",marginTop:10,justifyContent:"center"}}>
                    <img src={"./speakers/9.png"} alt="Speaker" />
                       
                    </div>
                    <div className="col-lg-3 col-sm-6 p-3" style={{display:"flex",marginTop:10,justifyContent:"center"}}>
                    <img src={"./speakers/10.png"} alt="Speaker" />
                       
                    </div>
                    <div className="col-lg-3 col-sm-6 p-3" style={{display:"flex",marginTop:10,justifyContent:"center"}}>
                    <img src={"./speakers/11.png"} alt="Speaker" />
                       
                    </div>

                    

                    

                 
                    
                </div>
            </section>
        );
    }
}
 
export default Speakers;